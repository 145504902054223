@import url(https://rsms.me/inter/inter.css);

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

.App {
  font-family: Inter;
  text-align: center;
}

table {
  text-align: center;
  border-collapse: collapse;
  border-radius: 10px;
  border: none;
  border-bottom: none;
  overflow: hidden;
}

.login-container {
  width: 300px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.login-form {
  width: 100%;
  margin-bottom: 10px;
}

.login-form input {
  max-width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #333333;
}

input {
  @apply dark:bg-slate-700 p-1 rounded-md hover:dark:bg-slate-600 hover:dark:text-white transition-colors duration-300 dark:active:border-none;
}

button {
  @apply dark:bg-slate-700 hover:dark:bg-slate-600 transition-colors duration-300;
}


input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0; 
}